<template>
    <div class="onlending-profiles-container">
        <div class="onlending-profiles-header">
            <div>Perfis para repasse</div>
            <Icon tooltip="Adicionar perfil">
                <Plus class="plus" @click="addProfile" />
            </Icon>
        </div>
        <div v-if="!forms?.length" class="empty">
            Nenhum perfil adicionado
        </div>
        <div v-for="(form, index) in forms" :key="index" class="selectors">  
            <multiselect
              v-if="!form.profile"
              track-by="id"
              label="name"
              placeholder="Selecionar perfil..."
              v-model="form.profile"
              class="with-border"
              :options="onlendingProfiles.filter(el => !forms.some(f => f.profile?.id === el.id))"
              :showLabels="false"
              :internalSearch="false"
              :searchable="false"
              :disabled="isDisabled(form)"
              @select="onSelectProfile"
            >
                <template slot="caret">
                    <div class="chevron">
                        <ChevronDown />
                    </div>
                </template>
                <template slot="noOptions">
                    Nenhuma opção
                </template>
                <template slot="noResult">
                    Nenhum resultado
                </template>
            </multiselect>

            <div v-else class="custom-input-prefix">
              <div
                class="profile-prefix"
                @click="!isDisabled(form) ? clearSelectors(index) : null"
              >
                {{ form.profile.name }}:
              </div>
              <multiselect
                  track-by="id"
                  label="name"
                  placeholder="Selecionar favorecido..."
                  v-model="form.beneficiary"
                  :options="beneficiaries"
                  :showLabels="false"
                  :internalSearch="true"
                  :searchable="true"
                  :disabled="isDisabled(form)"
                  @open="loadBeneficiaries(index)"
              >
                  <template slot="caret">
                      <div class="chevron">
                          <ChevronDown />
                      </div>
                  </template>
                  <template slot="noOptions">
                    <div class="no-option-wrapper">
                      <div>Nenhum favorecido compatível</div>
                      <div class="no-option-description">
                        Os favorecidos devem estar inseridos em alguma regra de repasse junto com o item selecionado
                      </div>
                    </div>
                  </template>
                  <template slot="noResult">
                      Nenhum resultado
                  </template>
              </multiselect>
            </div>

            <Icon tooltip="Remover favorecido">
                <Delete 
                  v-if="!isDisabled(form)"
                  class="icon" 
                  @click="removeProfile(index)"  
                />
            </Icon>
        </div>
    </div>
</template>
<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import Plus from '@/assets/icons/plus.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Icon from '@/components/General/Icon'
import Delete from '@/assets/icons/delete.svg'
import api from "@/modules/onlending/api.js"

export default {
    name: 'OnlendingProfilesModule',
    components: { Plus, ChevronDown, Icon, Delete },
    props: {
      item: Object,
      itemProfiles: Array,
    },
    created() {
      this.getOnlendingProfilesByClinicId()
      this.loadItemProfiles()
    },
    mounted(){
      !this.itemProfiles?.length && this.addProfile()      
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        forms: [],
        onlendingProfiles: [],
        beneficiaries: [],
        loadedIndex: null,
        loadedAnswer: null
      }
    },
    methods: {
        addProfile() {
          this.forms.push({ profile: null, beneficiary: null, disable: false })
        },
        removeProfile(index) {
          if(this.forms[index]?.id) {
           this.api.deleteAppointmentItemProfile(this.forms[index].id)
            .then(() => {
            })
            .catch(err => this.$toast.error(err.message)) 
          }
          this.forms.splice(index, 1)
          this.$toast.success('Perfil de repasse removido com sucesso!')
          this.updateForm()
        },
        onSelectProfile(profile) {
            this.getBeneficiariesByProfile(profile.id)
            this.updateForm()
        },
        loadItemProfiles() {
            if (this.itemProfiles?.length) {
              this.forms = this.itemProfiles
            }
        },
        clearSelectors(index) {
          this.forms[index].profile = null
          this.forms[index].beneficiary = null
        },
        updateForm() {
            this.$emit('change', this.forms)
        },
        getOnlendingProfilesByClinicId() {
            api.getOnlendingProfilesByClinicId(this.clinic.id)
            .then(({ data }) => {
                this.onlendingProfiles = data.filter(el => el.active === true)
            })
            .catch(err => this.$toast.error(err.message))
        },
        getBeneficiariesByProfile(profileId) {
          this.beneficiaries = []
          if (!this.item?.id) return
          
          const itemId = this.item?.item_type === "App\\Models\\TissGuide\\TissTableProcedure" ? 
            this.item.item.procedure_id :
            this.item.id

          api.getBeneficiariesByProfile(profileId, itemId)
          .then(({ data }) => {
              this.beneficiaries = data.filter((beneficiary, index) => {
                return data.findIndex(opt => opt.id === beneficiary.id) === index;
              })
          })
          .catch(err => this.$toast.error(err.message))
        },
        loadBeneficiaries(index){
          if(!this.beneficiaries?.length){
            this.getBeneficiariesByProfile(this.forms[index].profile.id)
          }
        },
        isDisabled(form){
          return form.onlending_status ? form.onlending_status !== 'Em aberto' : false
        }
    },
    watch: {
      itemProfiles(val) {
        if (val?.length) {
          this.forms = val
        }
      }
    }
}
</script>
<style lang="scss" scoped>
.onlending-profiles-container {
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-100);
  margin-bottom: 10px;
  .onlending-profiles-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blue-100);
    color: var(--dark-blue);
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    .plus {
        width: 18px;
        height: 18px;
        fill: var(--blue-500);
        cursor: pointer;
    }
  }
  .empty {
    width: 100%;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    color: var(--type-active);
  }
  .selectors {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 15px 10px 5px 10px;
  }
  .custom-input-prefix {
    width: 100%;
    display: inline-flex;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    height: 38px;
    border: 1px solid var(--neutral-300);
    padding: 15px 0 15px 16px;
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 8px;
    .profile-prefix {
      font-weight: 700;
      color: var(--type-active);
      cursor: pointer;
    }
  }
  .icon {
    cursor: pointer;
    margin-bottom: 8px;
  }
  .no-option-wrapper {
    display: flex;
    flex-direction: column;
    color: var(--type-active);
    .no-option-description {
      font-size: 11px;
      color: var(--type-placeholder);
    }
  }
}
</style>
